<template>
  <v-row class="pa-0 ma-0 mt-4" justify="end">
    <v-col cols="8" sm="6" md="4" class="pa-0">
      <v-card
        class="transition-fast-in-fast-out pa-0 ma-0 arrow_box"
        style="background: white;"
        justify-sm="center"
      >
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="1" class="pa-0 pt-3 text-center">
            <v-icon color="secondary" size="20" class="mt-n3"
              >mdi-information</v-icon
            ></v-col
          >
          <v-col cols="11" class="pa-0 d-flex">
            <h6 class="my-2 pl-2 text-caption primary--text">
              {{ $t("candidate.interest_explain") }}
            </h6>
            <div class="ml-auto mr-0">
              <v-icon @click="closeCard" class="mr-1 cursor-pointer" size="14"
                >mdi-close</v-icon
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      dontShowCandidateInterestAgain: "user/dontShowCandidateInterestAgain"
    }),
    goToFaqs() {
      this.$router.push({ name: "faqs" });
    },
    closeCard() {
      this.dontShowCandidateInterestAgain();
      this.$emit("closeCard");
    }
  }
};
</script>
<style lang="scss">
.arrow_box {
  position: relative;
  border: 3px solid #c2e1f5;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: white;
  border-width: 15px;
  margin-left: -15px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  //border-bottom-color: var(--v-primary-base) !important;
  border-width: 20px;
  margin-left: -20px;
}
</style>
