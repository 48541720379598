<template>
  <v-dialog
    v-model="openModal"
    max-width="550"
    transition="dialog-bottom-transition"
    @click:outside="closeWelcome()"
  >
    <v-container class="background rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="closeWelcome()"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 my-2" justify="center">
        <v-img
          alt="ApoTalent"
          class="shrink mr-2"
          src="@/assets/apotalent/Isotipo black.svg"
          transition="scale-transition"
          width="95"
        />
      </v-row>
      <v-row
        justify="center"
        class="primary--text text-h4 ma-0 pa-0 font-weight-semibold"
        >{{ keyValues[title] }}</v-row
      >
      <v-row class="secondary--text mx-4">{{ keyValues[text] }} </v-row>

      
      <div v-if="confirm">
        <v-row class="mx-2 my-8" justify="center">
          <v-col cols="6" class="pa-0 mt-2 text-center font-weight-medium">
            <div>
              <v-btn
                @click="closeWelcome()"
                color="secondary"
                class="text-capitalize"
                style="font-size: 16px"
                large
                rounded
              >
                {{ $t("offer.cancel") }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" class="pa-0 mt-2 text-center font-weight-medium">
            <div>
              <v-btn
                @click="$emit('confirm')"
                color="secondary"
                class="text-capitalize"
                style="font-size: 16px"
                large
                rounded
              >
                {{ $t("operation.confirm") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mx-2 my-8" justify="center">
          <v-col cols="6" class="pa-0 mt-2 text-center font-weight-medium">
            <div>
              <v-btn
                @click="closeWelcome()"
                color="secondary"
                class="text-capitalize"
                style="font-size: 16px"
                large
                rounded
              >
                {{ $t("operation.understood") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["title", "text", "openModal", "confirm"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" })
  },
  methods: {
    closeWelcome() {
      this.dialog = false;
      this.$emit("closeModal");
      // this.$router.push({ name: "register-validated" });
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
</style>
