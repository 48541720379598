<template>
  <v-container v-if="mounted">
    <Discard
      @discard="discardOffer"
      :openModal.sync="showDiscard"
      @confirmDiscard="discardOffer"
    />
    <custom-modal
      :openModal="incompleteCandidate"
      @closeModal="toggleIncompleteProfileModal"
      :text="'incomplete_candidate_text'"
      :title="'incomplete_candidate_title'"
    />
    <layout :back="true" />
    <v-row class="ma-0 pa-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="6">
        <v-list-item>
          <v-list-item-avatar tile size="60" color="grey"></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 primary--text">{{
              offer.position.name
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-caption secondary--text">
              {{ offer | formatAddress }}</v-list-item-subtitle
            >
            <v-col
              class="pa-0 mt-1"
              cols="12"
              v-if="offer.candidate_status != 'N'"
            >
              <v-chip
                small
                color="success"
                text-color="white"
                v-if="offer.candidate_status == 'F'"
              >
                {{ $t("candidate.fav") }}
              </v-chip>
              <v-chip
                small
                color="error"
                text-color="white"
                v-if="offer.candidate_status == 'D'"
              >
                {{ $t("candidate.discard") }}
              </v-chip>
            </v-col>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-row class="mt-4 ma-0 pa-0" justify-sm="center">
      <v-col
        cols="9"
        md="6"
        sm="8"
        class="ma-0 pa-0 mt-4 ml-4 text-left primary--text"
        >{{ $t("offer.detail") }}</v-col
      >
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="6" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>

    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="6" sm="8" class="ma-0 pa-0">
        <v-list-item class="text-subtitle-2 font-weight-light tertiary--text">
          <v-list-item-content>
            <!-- <v-list-item-subtitle class="mb-5 detailText primary--text">
              {{ $t("offer.validity") }}: 20/01/2021 - 20/06/2021
            </v-list-item-subtitle> -->
            <v-list-item-subtitle class="primary--text">
              <span class="font-weight-bold">{{
                $t("offer.pharmacy_title")
              }}</span>

              <span class="detailText"> {{ offer.position.name }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text" v-if="offer.expertise">
              <span class="font-weight-bold">{{ $t("offer.expertise") }}</span>
              <span class="detailText">{{ offer.expertise.name }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text">
              <span class="font-weight-bold">{{
                $t("offer.working_day")
              }}</span>
              <ul style="list-style-type: none">
                <li
                  v-for="(elem, index) in offer.working_day"
                  :key="index"
                  class="primary--text"
                >
                  <span class="detailText">
                    {{ "• " + elem.name + "." }}
                  </span>
                </li>
              </ul>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="offer.working_hours != null"
              class="primary--text font-weight-bold"
            >
              <span class="font-weight-bold"
                >{{ $t("offer.working_hours") }}
              </span>
              <span class="detailText">{{ offer.working_hours }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="offer.working_hours_comment"
              class="primary--text font-weight-bold"
            >
              <span class="font-weight-bold"
                >{{ $t("offer.working_hours_comment") }}
              </span>
              <span class="detailText">{{ offer.working_hours_comment }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text font-weight-bold">
              <span class="font-weight-bold"
                >{{ $t("offer.remuneration_model") }}
              </span>
              <span class="detailText">{{ offer.remuneration_model }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text font-weight-bold">
              <span class="font-weight-bold"
                >{{ $t("offer.incentives") }}
              </span>
              <span v-if="offer.incentives" class="detailText">{{
                $t("field.yes")
              }}</span>
              <span v-else class="detailText">{{ $t("field.no") }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text font-weight-bold">
              <span class="font-weight-bold"
                >{{ $t("offer.incorporation_date") }}
              </span>
              <span class="detailText">{{
                offer.incorporation_date | moment("DD-MM-YYYY")
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text">
              <span class="font-weight-bold">{{ $t("offer.contract") }} </span>
              <span class="detailText">{{ offer.contract_type.name }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="primary--text mb-2" v-if="offer.watch">
              <span class="font-weight-bold">{{ $t("offer.watch") }} </span>
              <span class="detailText">{{ offer.watch }}</span>
            </v-list-item-subtitle>
            <span>{{ $t("offer.required_language") }} </span>
            <v-list-item-subtitle
              v-for="(language, index) in offer.offer_languages.filter(
                elm => elm.required == true
              )"
              :key="'requiered_' + index"
              class="detailText primary--text"
            >
              <span v-if="language.required">
                {{ language.language }}
              </span>
            </v-list-item-subtitle>
            <span>{{ $t("offer.opcional_language") }} </span>
            <v-list-item-subtitle
              v-for="(language, index) in offer.offer_languages.filter(
                elm => elm.required == false
              )"
              :key="'optional_' + index"
              class="detailText primary--text"
            >
              <span v-if="!language.required">
                {{ language.language }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="offer.candidate_status != 'F'"
    >
      <v-col cols="12" md="6" sm="8" class="pa-0">
        <div class="text-right">
          <v-btn
            @click="changeCandidateOfferStatus(true)"
            x-small
            rounded
            color="transparent"
            class="success--text customButton"
            ><v-icon>mdi-plus</v-icon>{{ $t("offer.interested") }}</v-btn
          >
        </div>
      </v-col></v-row
    >
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="offer.candidate_status != 'D'"
    >
      <v-col cols="12" md="6" sm="8" class="pa-0">
        <div class="text-right">
          <v-btn
            @click="toggleModal"
            x-small
            rounded
            color="transparent"
            class="danger--text customButton"
            ><v-icon>mdi-close</v-icon>{{ $t("offer.not_interested") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      justify-sm="center"
      v-if="offer.candidate_status != 'D'"
    >
      <v-col cols="12" md="6" sm="8" class="pa-0">
        <transition name="fade" mode="out-in">
          <interest-offer-explain v-if="ShowCandidateInterest" />
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";

// Modal
import CustomModal from "@/components/modals/CustomModal.vue";
import Discard from "@/components/modals/discard.vue";
import { mapGetters, mapActions } from "vuex";
import InterestOfferExplain from "@/components/shared/InterestOfferExplain.vue";
export default {
  components: {
    layout,
    Discard,
    CustomModal,
    InterestOfferExplain
  },
  data() {
    return {
      value: null,
      mounted: false,
      showDiscard: false,
      incompleteCandidate: false
    };
  },
  async mounted() {
    this.enableLoading();
    await this.getOfferById({
      offer_id: this.$route.params.offer_id,
      candidate_id: this.childId
    });
    this.disableLoading();
    this.mounted = true;
  },
  computed: {
    ...mapGetters({ offer: "match/offer" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ ShowCandidateInterest: "user/ShowCandidateInterest" })
  },
  methods: {
    ...mapActions({ getOfferById: "match/getOfferById" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    async changeCandidateOfferStatus(isFavorite) {
      if (isFavorite) {
        this.enableLoading();
        try {
          await this.$store.dispatch("offer/changeCandidateOfferStatus", {
            offer_id: this.$route.params.offer_id,
            status: "F",
            candidate_id: this.childId
          });
          this.$notify({
            title: this.$i18n.t("notification.favorite_add"),
            text: this.$i18n.t("notification.favorite_add"),
            type: "success"
          });
          this.$router.push({ name: "offers" });
        } catch (error) {
          if (error.response.status == 412) {
            this.toggleIncompleteProfileModal();
          } else {
            this.$notify({
              title: this.$i18n.t("notification.favorite_add_error"),
              text: this.$i18n.t("notification.favorite_add_error"),
              type: "error"
            });
          }
        } finally {
          this.disableLoading();
        }
      }
    },
    toggleModal() {
      this.showDiscard = !this.showDiscard;
    },
    toggleIncompleteProfileModal() {
      this.incompleteCandidate = !this.incompleteCandidate;
    },
    async discardOffer() {
      try {
        this.toggleModal();
        await this.$store.dispatch("offer/changeCandidateOfferStatus", {
          offer_id: this.$route.params.offer_id,
          status: "D",
          candidate_id: this.childId
        });
        this.$notify({
          title: this.$i18n.t("notification.discard_offer"),
          type: "error"
        });
        this.$router.push({ name: "offers" });
      } catch {
        this.$notify({
          title: this.$i18n.t("notification.discard_offer_error"),
          type: "error"
        });
      }
    }
  }
};
</script>
<style scoped>
.customButton {
  box-shadow: none;
}
.detailText {
  font-size: 13px;
  margin-bottom: 3px;
  opacity: 0.6;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
